<template>
    <div class="quickCheckSettingWh">
        <div class="header-con">
            <h4>批检指标设置</h4>
            <span class="save-btn" @click="handleSave">保存</span>
        </div>
        <div class="main-con">
            <slider @load-table="handleLoadTable"></slider>
            <GBtableList v-if="isGB" :gb-data="gbData" :current-data="currentData"></GBtableList>
            <RBtableList
                v-else
                :rb-data="rbData"
                :current-data="currentData"
                ref="RBtableList"
            ></RBtableList>
        </div>
    </div>
</template>

<script>
import slider from './slider';
import GBtableList from './GBtableList';
import RBtableList from './RBtableList';
import util from '@/utils/util';
export default {
    components: { slider, GBtableList, RBtableList },
    props: { },
    data() {
        return {
            isGB: false, // 是否展示国标列表
            gbData: [], // 国标数据集合
            originalGBData: [], // 修改前国标数据
            currentData: {}, // 当前树节点信息
            rbData: [], // 让步和合格指标数据集合
            originalRBData: [], // 修改前让步和合格数据
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 加载表格
        handleLoadTable(data) {
            this.currentData = data;
            if (data.level === 1) {
                this.isGB = true;
                this.getGbData();
            } else if (data.level === 2 && data.childs.length === 0) {
                this.isGB = false;
                this.getRBData();
            } else if (data.level === 3) {
                this.isGB = false;
                this.getRBData();
            }
        },
        // 获取国标列表数据
        getGbData() {
            this.$axios
                .get('/interfaceApi/procurement/quality/config/gb/get?type_dic_code=' + this.currentData.type_dic_code)
                .then(res => {
                    if (res) {
                        this.originalGBData = JSON.parse(JSON.stringify(res));

                        res.forEach(item => {
                            item.gb_index = this.getItemSet(item.gb_index, item.index_type);
                        });
                        this.gbData = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 转化设置对象
        getItemSet(index_value, index_type) {
            let arr = ['', '', '并且', '', ''];
            index_value && (arr = index_value.split('|'));
            const data = {
                symbol_min: arr[0],
                value_min: arr[1],
                join_value: arr[2] || '并且',
                symbol_max: arr[3],
                value_max: arr[4],
                custom_value: '',
            };
            if (index_type === 1) {
                data.custom_value = index_value;
            }
            return data;
        },
        // 保存
        handleSave() {
            if (this.isGB) {
                this.gbSave();
            } else {
                this.rbSave();
            }
        },
        // 保存国标数据
        gbSave() {
            const editData = JSON.parse(JSON.stringify(this.gbData));
            // 合并下设置值 成一个字段
            editData.forEach(item => {
                if (item.index_type === 0) {
                    if (item.gb_index.value_min && item.gb_index.value_max) {
                        // eslint-disable-next-line max-len
                        item.gb_index = `${item.gb_index.symbol_min}|${item.gb_index.value_min}|${item.gb_index.join_value}|${item.gb_index.symbol_max}|${item.gb_index.value_max}`;
                    } else if (item.gb_index.value_min) {
                        item.gb_index = `${item.gb_index.symbol_min}|${item.gb_index.value_min}|||`;
                    } else if (item.gb_index.value_max) {
                        item.gb_index = `|||${item.gb_index.symbol_max}|${item.gb_index.value_max}`;
                    } else {
                        item.gb_index = null;
                    }
                } else {
                    item.gb_index = item.gb_index.custom_value;
                }
            });
            const gbData = [];
            this.originalGBData.map((item, index) => {
                const boo = util.Compare(item, editData[index]);
                if (!boo) {
                    gbData.push(editData[index]);
                }
            });
            if (gbData.length > 0) {
                this.$axios
                    .post('/interfaceApi/procurement/quality/config/gb/save', gbData)
                    .then(res => {
                        if (res) {
                            this.$message.success('保存成功');
                            this.getGbData();
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.success('保存成功');
            }

        },
        // 获取让步/合格数据
        getRBData() {
            /**
             * string type_dic_code, string child_type_code, string child_type_dic_code, string spec_code = "", string spec_dic_code = ""
             */
            this.$axios
                .get(`/interfaceApi/procurement/quality/config/batchtesting/index?type_dic_code=${this.currentData.type_dic_code}`
                    + `&child_type_code=${this.currentData.child_type_code}&child_type_dic_code=${this.currentData.child_type_dic_code}`
                    + `&spec_code=${this.currentData.spec_code}&spec_dic_code=${this.currentData.spec_dic_code}`
                )
                .then(res => {
                    if (res) {
                        this.originalRBData = JSON.parse(JSON.stringify(res));
                        res.forEach(item => {
                            item.standard_index = this.getItemSet(item.standard_index, item.index_type);
                            item.not_standard_index = this.getItemSet(item.not_standard_index, item.index_type);
                        });
                        this.rbData = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存指标
        rbSave() {
            const editData = JSON.parse(JSON.stringify(this.$refs.RBtableList.tableData));
            if (editData.length === 0) {
                return;
            }
            // 合并下设置值 成一个字段
            editData.forEach(item => {
                if (item.index_type === 0) {
                    if (item.standard_index.value_min && item.standard_index.value_max) {
                        item.standard_index = `${item.standard_index.symbol_min}`
                            + `|${item.standard_index.value_min}`
                            + `|${item.standard_index.join_value}`
                            + `|${item.standard_index.symbol_max}`
                            + `|${item.standard_index.value_max}`;
                    } else if (item.standard_index.value_min) {
                        item.standard_index = `${item.standard_index.symbol_min}|${item.standard_index.value_min}|||`;
                    } else if (item.standard_index.value_max) {
                        item.standard_index = `|||${item.standard_index.symbol_max}|${item.standard_index.value_max}`;
                    } else {
                        item.standard_index = '';
                    }
                    if (item.not_standard_index.value_min && item.not_standard_index.value_max) {
                        item.not_standard_index = `${item.not_standard_index.symbol_min}`
                            + `|${item.not_standard_index.value_min}`
                            + `|${item.not_standard_index.join_value}`
                            + `|${item.not_standard_index.symbol_max}`
                            + `|${item.not_standard_index.value_max}`;
                    } else if (item.not_standard_index.value_min) {
                        item.not_standard_index = `${item.not_standard_index.symbol_min}|${item.not_standard_index.value_min}|||`;
                    } else if (item.not_standard_index.value_max) {
                        item.not_standard_index = `|||${item.not_standard_index.symbol_max}|${item.not_standard_index.value_max}`;
                    } else {
                        item.not_standard_index = '';
                    }
                } else {
                    item.standard_index = item.standard_index.custom_value;
                    item.not_standard_index = item.not_standard_index.custom_value;
                }
            });
            const rbData = [];
            this.originalRBData.map((item, index) => {
                const boo = util.Compare(item, editData[index]);
                if (!boo) {
                    rbData.push(editData[index]);
                }
            });
            if (editData.length > this.originalRBData.length) {
                editData.map((item, index) => {
                    if (index > this.originalRBData.length - 1) {
                        rbData.push(item);
                    }
                });
            }
            if (rbData.length > 0) {
                // this.$refs.RBtableList.setRule();
                this.$axios
                    .post('/interfaceApi/procurement/quality/config/batchtesting/index/save', rbData)
                    .then(res => {
                        if (res) {
                            this.$message.success('保存成功');
                            this.getRBData();
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.success('保存成功');
            }

        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.quickCheckSettingWh
    font-size .16rem
    overflow hidden
    .header-con
        height .4rem
        display flex
        background #fff
        // margin-bottom .2rem
        justify-content space-between
        line-height .4rem
        padding 0 .1rem
        .save-btn
            width .6rem
            height .28rem
            text-align center
            background #1577D2
            border-radius .04rem
            color #fff
            margin-top .06rem
            line-height .28rem
            cursor pointer
    .main-con
        height calc(100% - .6rem)
</style>
