<template>
    <div class="quickCheckSetting-table-gb">
        <!-- <div class="btn-con">
            <batchSetRule ref="batchSetRule" :current-data="currentData"></batchSetRule>
        </div> -->
        <div class="table-con table-bg">
            <el-table
                :data="gbData"
                height="100%"
                border
                style="width: 100%"
                stripe
            >
                <el-table-column
                    prop="item_name"
                    label="检测项目"
                    width="220"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.item_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="item_name"
                    label="单位"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-input class="center-input" v-model="scope.row.unit" placeholder="请输入"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="item_name"
                    label="指标类型"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.index_type">
                            <el-option label="区间" :value="0"></el-option>
                            <el-option label="指定值" :value="1"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="gb_value"
                    label="国标指标"
                >
                    <template slot-scope="scope">
                        <template v-if="scope.row.index_type==0">
                            <el-select class="symbol-tag-select" v-model="scope.row.gb_index.symbol_min" placeholder="">
                                <el-option
                                    v-for="item in optionsMin"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input class="value-input" v-model="scope.row.gb_index.value_min">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                            <el-select class="join-select" v-model="scope.row.gb_index.join_value">
                                <el-option label="并且" value="并且"></el-option>
                                <el-option label="或者" value="或者"></el-option>
                            </el-select>
                            <el-select class="symbol-tag-select" v-model="scope.row.gb_index.symbol_max" placeholder="">
                                <el-option
                                    v-for="item in optionsMax"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                            <el-input class="value-input" v-model="scope.row.gb_index.value_max">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                        </template>
                        <template v-else>
                            <el-input class="custom-value-input" placeholder="请输入" v-model="scope.row.gb_index.custom_value">
                                <template slot="suffix">
                                    {{ scope.row.unit }}
                                </template>
                            </el-input>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="备注"
                    width="250"
                >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.remarks" placeholder="请输入备注信息"></el-input>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
// import batchSetRule from './batchSetRule';

export default {
    // components: { batchSetRule },
    props: {
        gbData: {
            type: [Array],
        },
        currentData: {
            type: [Object],
        },
    },
    data() {
        return {
            tableData: [
                {
                    id: '',
                    type_code: '', // 分类编码
                    type_dic_code: '', // 分类编码
                    type_name: '', // 分类名称
                    item_code: '', // 检查编码
                    item_name: '', // 检查项
                    remarks: '', // 备注
                    sort_num: 0, // 顺序号
                    index_type: 0, // 指标类型 0区间 1指定值
                    gb_index: '', // 国标指标
                },
            ],
            optionsMin: [
                {
                    value: '=',
                    label: '=',
                },
                {
                    value: '!=',
                    label: '!=',
                },
                {
                    value: '>',
                    label: '>',
                },
                {
                    value: '≥',
                    label: '≥',
                },
                {
                    value: '<',
                    label: '<',
                },
                {
                    value: '≤',
                    label: '≤',
                },

            ],
            optionsMax: [
                {
                    value: '=',
                    label: '=',
                },
                {
                    value: '!=',
                    label: '!=',
                },
                {
                    value: '>',
                    label: '>',
                },
                {
                    value: '≥',
                    label: '≥',
                },
                {
                    value: '<',
                    label: '<',
                },
                {
                    value: '≤',
                    label: '≤',
                },

            ],
            radio: '', // 单选
        };
    },
    watch: {},
    computed: {},
    methods: {
        setRule() {
            this.$refs.batchSetRule.saveBatchRule();
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus">
.quickCheckSetting-table-gb
    float left
    width calc(100% - 2.4rem)
    margin-left .2rem
    height 100%
    background #fff
    padding .1rem
    .btn-con
        display flex
        align-items center
        margin-bottom .1rem
        .btn
            padding .05rem .1rem
            text-align center
            background #1577D2
            border-radius .04rem
            display inline-block
            color #ffffff
            cursor pointer
    .table-con
        height calc(100% - .4rem)
        .iconshanchu
            color #D5000F
            cursor pointer
        .cell
            display flex
            justify-content center
            align-items center
            padding 2px
        .el-select
            display: inline-block;
            margin-right: .05rem;
        .value-input
            width: 0.9rem;
            display: inline-block;
            margin-right: .05rem;
        input
            padding 0 0.08rem
            height 0.36rem
        .symbol-tag-select
            width 0.8rem!important
        .join-select
            width 0.7rem
            input
                border 1px solid #A9B3D2
                background #F1F5FE
                color #556FC6
        .custom-value-input
            margin-left -0.02rem
            width 3.3rem;
        .center-input
            input
                text-align center
        .el-input__suffix
            display flex
            align-items center
            color #666
    .el-table
        th
            background #fff !important
            padding 2px !important
            color #333 !important
            .cell
                background #F8F8F8 !important
</style>
